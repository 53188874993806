import {
	Register,
	Login,
	Risk,
	RiskAssessment,
	RiskAssessmentDetail,
	Insure,

	InsureDetail,
	Endorse,
	Claim,
	EndorseDetail,
	ClaimDetail,
	FollowRecord,
	HomePage,
	Agreement,
	Secret,
	User,
	Enterprise,
	PersonalInfo,
	NewAccount,
	PasswordSetting,
	EnterpriseAccount,
	DeleteAccount,
	RFQ,
	ClimateWarning,
	City,
	CitySet,
	DisasterGuide,
	PVInsurance,
	PVLogin,
	MyPolicy,
	MyPolicyDetail,
	Activity,
	WorkOrderRisk,
	InsureOrder,
	ClaimCase,
	ClaimCaseDetail,
	ProcessRecord,
	ComplaintUpdate,
	MonthReport,
	MonthRiskDetail,
	NoFoundPage,
	Xiaojinwu,
	ChargingStation,
	Guangfu,
	GuangfuPolicyDetail,
	GuangfuCase,
	EndorseGuangFu,
	EndorseDetailGuangFu,
	pvclaimcasedetail,
	Extension,
	WorkOrderInsurance,
	SubmitSuccess,
	GuangWuDi,
	Skyworth,
	SkyworthInformationGathering,
	SkyworthSubmitSuccess,
	PvReport,
	PvReportDetail,
} from "../pages";

export type RouterType = {
	path: string;
	component: React.LazyExoticComponent<any>;
	root: string[];
	notExect?: boolean;
};
const NoFoundPageRouter: RouterType = {
	path: "/404",
	component: NoFoundPage,
	root: [],
};
const RegisterRouter: RouterType = {
	path: "/register",
	component: Register,
	root: [],
};
const LoginRouter: RouterType = {
	path: "/login",
	component: Login,
	root: [],
};
const RiskRouter: RouterType = {
	path: "/risk",
	component: Risk,
	root: [],
};
// 工单险风险评估报告
const RiskAssessmentRouter: RouterType = {
	path: "/risk-assessment",
	component: RiskAssessment,
	root: [],
};
// 工单险风险评估报告-详情
const RiskAssessmentDetailRouter: RouterType = {
	path: "/risk-assessment-detail",
	component: RiskAssessmentDetail,
	root: [],
};
const InsureRouter: RouterType = {
	path: "/insure",
	component: Insure,
	root: [],
};
const InsureDetailRouter: RouterType = {
	path: "/insure-detail",
	component: InsureDetail,
	root: [],
};
const EndorseRouter: RouterType = {
	path: "/endorse",
	component: Endorse,
	root: [],
};
/* EndorseGuangFu,
	EndorseDetailGuangFu */
	const EndorseGuangFuRouter: RouterType = {
		path: "/endorse-guangfu",
		component: EndorseGuangFu,
		root: [],
};
const EndorseDetailGuangFuRouter: RouterType = {
	path: "/endorse-detail-guangfu",
	component: EndorseDetailGuangFu,
	root: [],
};
const ClaimRouter: RouterType = {
	path: "/claim",
	component: Claim,
	root: [],
};
const EndorseDetailRouter: RouterType = {
	path: "/endorse-detail",
	component: EndorseDetail,
	root: [],
};
const ClaimDetailRouter: RouterType = {
	path: "/claim-detail",
	component: ClaimDetail,
	root: [],
};
const FollowRecordRouter: RouterType = {
	path: "/follow-record",
	component: FollowRecord,
	root: [],
};
const HomePageRouter: RouterType = {
	path: "/home",
	component: HomePage,
	root: [],
};

const AgreementRouter: RouterType = {
	path: "/agreement",
	component: Agreement,
	root: [],
};
const SecretRouter: RouterType = {
	path: "/secret",
	component: Secret,
	root: [],
};
// 个人中心
const UserRouter: RouterType = {
	path: "/user",
	component: User,
	root: [],
};
// 企业信息
const EnterpriseRouter: RouterType = {
	path: "/enterprise",
	component: Enterprise,
	root: [],
};
// 个人信息
const PersonalInfoRouter: RouterType = {
	path: "/personal-info",
	component: PersonalInfo,
	root: [],
};
// 新增账号
const NewAccountRouter: RouterType = {
	path: "/new-account",
	component: NewAccount,
	root: [],
};
// 密码设置
const PasswordSettingRouter: RouterType = {
	path: "/password-setting",
	component: PasswordSetting,
	root: [],
};
// 企业账户
const EnterpriseAccountRouter: RouterType = {
	path: "/enterprise-account",
	component: EnterpriseAccount,
	root: [],
};
// 删除账号
const DeleteAccountRouter: RouterType = {
	path: "/delete-account",
	component: DeleteAccount,
	root: [],
};

const RFQRouter: RouterType = {
	path: "/rfq",
	component: RFQ,
	root: [],
};

// 气候预警
const ClimateWarningRouter: RouterType = {
	path: "/climate-warning",
	component: ClimateWarning,
	root: [],
};
// 城市选择
const CityRouter: RouterType = {
	path: "/city",
	component: City,
	root: [],
};

// 城市选择
const CitySetRouter: RouterType = {
	path: "/city-set",
	component: CitySet,
	root: [],
};

// 防灾指引
const DisasterGuideRouter: RouterType = {
	path: "/disaster-guide",
	component: DisasterGuide,
	root: [],
};
// 光伏保险页面
const PVInsuranceRouter: RouterType = {
	path: "/pv-insurance",
	component: PVInsurance,
	root: [],
};

const GuangWuDiRouter: RouterType = {
	path: "/guang-wu-di",
	component: GuangWuDi,
	root: [],
};
// 光伏保险-登录
const PVLoginRouter: RouterType = {
	path: "/pv-login",
	component: PVLogin,
	root: [],
};

// 我的保单(光伏保险)
const MyPolicyRouter: RouterType = {
	path: "/my-policy",
	component: MyPolicy,
	root: [],
};

// 我的保单详情(光伏保险)
const MyPolicyDetailRouter: RouterType = {
	path: "/my-policy-detail",
	component: MyPolicyDetail,
	root: [],
};

const ActivityRouter: RouterType = {
	path: "/activity/guangfu",
	component: Activity,
	root: [],
};
const ExtensionRouter: RouterType = {
	path: "/extension",
	component: Extension,
	root: [],
};
const ActivityXiaojinwuRouter: RouterType = {
	path: "/activity/platv1",
	component: Xiaojinwu,
	root: [],
};
// ChargingStation
const ChargingStationRouter: RouterType = {
	path: "/activity/charging-station",
	component: ChargingStation,
	root: [],
};
//工单风险综览
const WorkOrderRiskRouter: RouterType = {
	path: "/work-order-risk",
	component: WorkOrderRisk,
	root: [],
};

//工单投保订单
const InsureOrderRouter: RouterType = {
	path: "/insure-order",
	component: InsureOrder,
	root: [],
};

//工单理赔案件
const ClaimCaseRouter: RouterType = {
	path: "/claim-case",
	component: ClaimCase,
	root: [],
};

//工单理赔案件详情
const ClaimCaseDetailRouter: RouterType = {
	path: "/claim-case-detail",
	component: ClaimCaseDetail,
	root: [],
};

//工单理赔案件----处理记录
const ProcessRecordRouter: RouterType = {
	path: "/process-record",
	component: ProcessRecord,
	root: [],
};

//赔案更新
const ComplaintUpdateRouter: RouterType = {
	path: "/complaint-update",
	component: ComplaintUpdate,
	root: [],
};

//工单风险报告列表
const MonthReportRouter: RouterType = {
	path: "/month-report",
	component: MonthReport,
	root: [],
};

//月度风险报告详情
const MonthRiskDetailRouter: RouterType = {
	path: "/month-risk-detail",
	component: MonthRiskDetail,
	root: [],
};

//月度风险报告详情
const GuangfuRouter: RouterType = {
	path: "/guangfu-policy",
	component: Guangfu,
	root: [],
};

//月度风险报告详情
const GuangfuPolicyDetailRouter: RouterType = {
	path: "/guangfu-policy-detail",
	component: GuangfuPolicyDetail,
	root: [],
};
const GuangfuCaseRouter: RouterType = {
	path: "/guangfu-case",
	component: GuangfuCase,
	root: [],
};
const pvclaimcasedetailRouter: RouterType = {
	path: "/pv-claim-case-detail",
	component: pvclaimcasedetail,
	root: [],
};
// WorkOrderInsurance
const WorkOrderInsuranceRouter: RouterType = {
	path: "/work-order-insurance",
	component: WorkOrderInsurance,
	root: [],
};
const SubmitSuccessRouter: RouterType = {
	path: "/submit-success",
	component: SubmitSuccess,
	root: [],
};
const SkyworthRoute: RouterType = {
	path: "/Skyworth",
	component: Skyworth,
	root: [],
};
// SkyworthInformationGatheringRouter  SkyworthInformationGathering
const SkyworthInformationGatheringRouter: RouterType = {
	path: "/Skyworth-information-gathering",
	component: SkyworthInformationGathering,
	root: [],
};
// SkyworthSubmitSuccess
const SkyworthSubmitSuccessRouter: RouterType = {
	path: "/Skyworth-submit-success",
	component: SkyworthSubmitSuccess,
	root: [],
};

const PvReportRouter: RouterType = {
	path: "/pv-report",
	component: PvReport,
	root: [],
};

const PvReportDetailRouter: RouterType = {
	path: "/pv-report-detail",
	component: PvReportDetail,
	root: [],
};
// Skyworth

// SubmitSuccess
// pvclaimcasedetail
// GuangfuPolicyDetail
// 总路由
const Routers: RouterType[] = [
	// RegisterRouter,
	NoFoundPageRouter,
	// LoginRouter,
	RiskRouter,
	RiskAssessmentRouter,
	RiskAssessmentDetailRouter,
	InsureRouter,
	InsureDetailRouter,
	EndorseRouter,
	ClaimRouter,
	EndorseDetailRouter,
	ClaimDetailRouter,
	FollowRecordRouter,
	HomePageRouter,
	AgreementRouter,
	SecretRouter,
	UserRouter,
	EnterpriseRouter,
	PersonalInfoRouter,
	NewAccountRouter,
	PasswordSettingRouter,
	EnterpriseAccountRouter,
	DeleteAccountRouter,
	RFQRouter,
	ClimateWarningRouter,
	CityRouter,
	CitySetRouter,
	DisasterGuideRouter,
	PVInsuranceRouter,
	PVLoginRouter,
	MyPolicyRouter,
	MyPolicyDetailRouter,
	ActivityRouter,
	WorkOrderRiskRouter,
	InsureOrderRouter,
	ClaimCaseRouter,
	ClaimCaseDetailRouter,
	ProcessRecordRouter,
	ComplaintUpdateRouter,
	MonthReportRouter,
	MonthRiskDetailRouter,
	ActivityXiaojinwuRouter,
	GuangfuRouter,
	GuangfuPolicyDetailRouter,
	GuangfuCaseRouter,
	EndorseGuangFuRouter,
	EndorseDetailGuangFuRouter,
	pvclaimcasedetailRouter,
	ExtensionRouter,
	WorkOrderInsuranceRouter,
	SubmitSuccessRouter,
	ChargingStationRouter,
	GuangWuDiRouter,
	SkyworthRoute,
	SkyworthInformationGatheringRouter,
	SkyworthSubmitSuccessRouter,
	PvReportRouter,
	PvReportDetailRouter
];

export { Routers };
